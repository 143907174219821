<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('projectsWithdrawals.projectsWithdrawalsOperations')"
                     :description="$t('projectsWithdrawals.fromHereYouCanControlprojectsWithdrawalsOperations')" />
        <indexTable :searchText="$t('projectsWithdrawals.searchForAnprojectsWithdrawalsOperations')"
                    :emptyTableText="$t('projectsWithdrawals.thereAreNoprojectsWithdrawalsOperations')"
                    :buttonRole="$user.role.projects_withdrawals_add"
                    :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                    :statusSearch="true"
                    :statusValues="[
                      { title: $t('orders.situation'), value: ''},
                      { title: $t('orders.all'), value: ''},
                      { title: $t('invoices.draft'), value: 0 },
                      { title: $t('invoices.approved'), value: 1 },
                    ]"
                    :cloumns="[
                                  {column: 'code',    title: $t('projectsWithdrawals.operation') ,type: 'mainLink' , sort: true },
                                  {column: 'project_id',    title: $t('projectsWithdrawals.projectName'), type: 'link', to:'project', sort: true, link: true},
                                  {column: 'store_id',    title: $t('projectsWithdrawals.storeName'), type: 'link', to:'store', sort: true, link: true},
                                  {column: 'date',    title: $t('projectsWithdrawals.theDateOfTheOperation') ,type: 'text' , sort: true },
                                  {column: 'total',    title: $t('projectsWithdrawals.Total') ,type: 'text' , sort: true },
                                  {column: 'description',    title: $t('projectsDeposits.thatIsAbout') ,type: 'text' , sort: true },
                                  {column: 'status', title: $t('bills.situation') ,type: 'status' , sort: true ,  values: [
                                        {title: $t('invoices.draft'), value: 0, color: 'dark'},
                                        {title: $t('invoices.approved'), value: 1, color: 'success'},
                                      ]
                                  },
                                  {column: 'options', title: $t('projectsWithdrawals.settings'), type: 'options', options: [
                                      {name: 'show'},
                                      {name: 'edit',   role: $user.admin || $user.role.projects_withdrawals_edit},
                                      {name: 'file'},
                                      {name: 'printprojectsWithdrawal',   role: true},
                                      {name: 'delete', role: $user.admin || $user.role.projects_withdrawals_delete},
                                  ]}
                                ]"
                    :deleteText="{
                                 attention: $t('allerts.Attention'),
                                 areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                                 close: $t('allerts.close'),
                                 confirm: $t('allerts.confirm'),
                                 loading: $t('allerts.loading'),
                                }" />
      </div>
    </div>
  </div>
</template>
<script>

import indexHeader from "../elements/index/indexHeader.vue";
import indexTable from "../elements/index/indexTable.vue";

export default {
  data() {
    return {
      path: "/projectsWithdrawals"
    };
  },
  components: {
    indexHeader,
    indexTable,
  },
};
</script>
